<template>
	<main>
		<div class="fw">
			<h1 class="fw__title">Создание договора</h1>

			<div class="fw__progress">
				<ul class="fw__breadcrumbs">
					<li class="fw__breadcrumbs__item">
						<div class="fw__breadcrumbs__element" :class="[stepIndex === 0 ? 'fw__breadcrumbs__element--active' : '']">
							1
						</div>
					</li>
					<li class="fw__breadcrumbs__item">
						<div class="fw__breadcrumbs__element" :class="[stepIndex === 1 ? 'fw__breadcrumbs__element--active' : '']">
							2
						</div>
					</li>
					<li class="fw__breadcrumbs__item">
						<div class="fw__breadcrumbs__element" :class="[stepIndex === 2 ? 'fw__breadcrumbs__element--active' : '']">
							<span class="done"></span></div>
					</li>
				</ul>
			</div>

			<form>
				<fieldset class="fw__inputs" v-show="stepIndex === 0">
					<input type="text" v-model="finder.link" placeholder="Ссылка*"/>
					<input class="fw__button" type="button" value="Далее" @click.prevent="next"/>
				</fieldset>

				<fieldset class="fw__inputs" v-show="stepIndex === 1">
					<div v-show="!hideBeforeAjax">
						<img src="https://cdn.dribbble.com/users/1319489/screenshots/2946353/monkey-1.gif" alt="">
					</div>

					<div v-show="!hideAfterAjax">
						<label>ФИО клиента:</label>
						<input type="text" v-model="form.full_name" placeholder="ФИО"/>

						<label>Номер договора:</label>
						<input type="text" v-model="form.dog_num" placeholder="Номер договора"/>

						<label>Город:</label>
						<input type="text" v-model="form.city" placeholder="Город"/>

						<label>Дата заключения договора:</label>
						<input type="text" v-model="form.formatted_date" placeholder="Дата заключения договора"/>

						<label>Юр. лицо*</label>
						<b-form-select class="fw__inputs__selector" v-model="form.org" @change="orgAction" :options="orgValues"></b-form-select>

						<label>Сумма оплаченных денег</label>
						<input type="text" v-model="form.pay_made" placeholder="Сумма оплаченных денег" data-label="Сумма оплаченных денег"/>

						<label>Тип договора</label>
						<b-form-group class="fw__inputs checkbox_list" v-slot="{ ariaDescribedby }">
							<b-form-radio-group
									class="checkbox_container"
									@change="backSelect()"
									v-model="form.back"
									:options="backOptions"
									:aria-describedby="ariaDescribedby"
							></b-form-radio-group>
						</b-form-group>

						<div class="sum-container" v-show="totalRefundShow">
							<label>Общая сумма возврата</label>
							<input type="text" v-model="form.refund" @input="actionChangeRefund" placeholder="Общая сумма возврата"/>
						</div>

						<div v-show="dateTerminationShow">
							<label>Дата расторжения</label>
							<b-form-datepicker
									v-model="form.termination_date"
									placeholder="Дата расторжения"
									@input="actionChangeTerminationDate"
							></b-form-datepicker>
						</div>
						<div v-show="fullMonthsShow">
							<label>Кол-во полных месяцев</label>
							<input @input="actionChangeFullMonth" type="number" v-model="form.full_months" placeholder="Кол-во полных месяцев"/>
						</div>
						<div>
              <div v-show="costMedShow">
                <label>Медицинские услуги</label>
                <input type="text" v-model="form.cost_med" placeholder="Медицинские услуги"/>
              </div>
              <div v-show="costLawShow">
                <label>Юридические услуги</label>
                <input type="text" v-model="form.cost_law" placeholder="Юридические услуги"/>
              </div>
              <div v-show="paySpecShow">
                <label>Оплата спецификации</label>
                <input type="text" v-model="form.pay_spec" placeholder="Сумма удержания МЕД"/>
              </div>
              <div v-show="payMedShow">
                <label>Оплата по МЕД</label>
                <input type="text" v-model="form.pay_med" placeholder="Сумма удержания МЕД"/>
              </div>
              <div v-show="payLawShow">
                <label>Оплата по ЮР</label>
                <input type="text" v-model="form.pay_law" placeholder="Сумма удержания МЕД"/>
              </div>
              <div v-show="paySoftwareUnlimitedShow">
                <label>Оплата ПО бессрочное</label>
                <input type="text" v-model="form.pay_software_unlimited" placeholder="Оплата ПО бессрочное"/>
              </div>
              <div v-show="paySoftwareUrgentShow">
                <label>Оплата ПО срочное</label>
                <input type="text" v-model="form.pay_software_urgent" placeholder="Оплата ПО срочное"/>
              </div>
              <div v-show="amountRetentionSpecShow">
                <label>Сумма удержания по спецификации</label>
                <input type="text" v-model="form.amount_retention_spec" placeholder="Сумма удержания по спецификации"/>
              </div>
              <div v-show="amountRetentionUnlimitedShow">
                <label>Сумма удержания ПО бессрочное</label>
                <input type="text" v-model="form.amount_retention_unlimited" placeholder="Сумма удержания ПО бессрочное"/>
              </div>
              <div v-show="amountRetentionUrgentShow">
                <label>Сумма удержания ПО срочное</label>
                <input type="text" v-model="form.amount_retention_urgent" @input="actionChangeRefund" placeholder="Сумма удержания ПО срочное"/>
              </div>
              <div v-show="amountRefundUnlimitedShow">
                <label>Сумма возврата ПО бессрочное</label>
                <input type="text" v-model="form.amount_refund_unlimited" @input="actionChangeRefund" placeholder="Сумма возврата ПО бессрочное"/>
              </div>
              <div v-show="amountRefundUrgentShow">
                <label>Сумма возврата ПО срочное</label>
                <input type="text" v-model="form.amount_refund_urgent" @input="actionChangeRefund" placeholder="Сумма возврата ПО срочное"/>
              </div>
              <div v-show="retentionMedShow">
                <label>Сумма удержания МЕД</label>
                <input type="text" v-model="form.retention_med" placeholder="Сумма удержания МЕД"/>
              </div>
              <div v-show="retentionLawShow">
                <label>Сумма удержания ЮР</label>
                <input type="text" v-model="form.retention_law" placeholder="Сумма удержания ЮР"/>
              </div>
              <div v-show="refundSpecShow">
                <label>Сумма возврата спецификации</label>
                <input type="text" v-model="form.refund_spec" @input="actionChangeRefund" placeholder="Сумма возврата спецификации"/>
              </div>
              <div v-show="refundMedShow">
                <label>Сумма возврата МЕД</label>
                <input type="text" v-model="form.refund_med" @input="actionChangeRefund" placeholder="Сумма возврата МЕД"/>
              </div>
              <div v-show="refundLawShow">
                <label >Сумма возврата ЮР</label>
                <input type="text" v-model="form.refund_law" @input="actionChangeRefund" placeholder="Сумма возврата ЮР"/>
              </div>
              <div v-show="retentionSpecShow">
                <label>Сумма удержания по спецификации</label>
                <input type="text" v-model="form.retention_spec" placeholder="Сумма удержания по спецификации"/>
              </div>
              <div v-show="ndsRefundShow">
                <label >НДС (с возврата)</label>
                <input type="text" v-model="form.nds" placeholder="НДС (с возврата)">
              </div>
              <div v-show="ndsRetentionShow">
                <label>НДС (с удержания)</label>
                <input type="text" v-model="form.nds2" placeholder="НДС (с удержания)">
              </div>
              <div v-show="costPeriodLawShow">
                <label>Сумма расчетного периода ЮР</label>
                <input type="text" v-model="form.period_cost_law" placeholder="Сумма расчетного периода ЮР">
              </div>
              <div v-show="costPeriodMedShow">
                <label>Сумма расчетного периода МЕД</label>
                <input type="text" v-model="form.period_cost_med" placeholder="Сумма расчетного периода МЕД">
              </div>
						</div>
						<div v-show="bankBikShow">
							<label>БИК</label>
							<input v-model="form.bik" placeholder="БИК" type="text">
						</div>
						<div>
							<label v-show="bankRSShow">Р/С</label>
							<input v-show="bankRSShow" v-model="form.rs" placeholder="Р/С" type="text">

							<label v-show="bankNameShow">Банк</label>
							<input v-show="bankNameShow" v-model="form.bank" placeholder="Банк" type="text">
						</div>
						<div v-show="creditInfoShow">
							<label>НомерСерия паспорта</label>
              <input type="text" v-model="form.passport_num">

							<label>Данные Паспорта</label>
              <input type="text" v-model="form.passport_data">

							<label>Имя клиента</label>
              <input type="text" v-model="form.client_name">

							<label>Фамилия клиента</label>
              <input type="text" v-model="form.client_lastname">

							<label>Отчество клиента</label>
              <input type="text" v-model="form.client_middle_name">

							<label>Номер договора Банка</label>
              <input type="text" v-model="form.bank_contract">

							<label>Дата создания договора</label>
              <input type="text" v-model="form.credit_date_create">
						</div>
						<input class="fw__button fw__button--negative" type="button" value="Назад" @click.prevent="back"/>
						<input class="fw__button" type="submit" value="Далее" @click.prevent="next"/>
					</div>
				</fieldset>

				<section class="fw__result" v-show="stepIndex === 2">
					<h4 class="fw__result__title">Ваш документ</h4>
					<a id="download" href="#" @click.prevent="generate">Скачать документы</a>
          <div class="checkbox-container">
            <input
                id="agreement"
                type="checkbox"
                v-model="form.in_agreement"
                value="1"
            />
            <label for="agreement">Документы согласованы с бухгалтерией</label>
          </div>
          <div class="button-container">
            <input class="fw__button fw__result__button" type="button" value="Отправить в мобильное приложение" @click.prevent="init">
          </div>
          <div class="button-container">
            <input class="fw__button fw__result__button" type="button" value="Сгенерировать другой" @click.prevent="init">
          </div>
				</section>
			</form>
		</div>
	</main>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'TerminationDocGenForm',
	computed: {
		...mapGetters(['term_docgen', 'contract']),
	},
	data() {
		return {
			finder: {
				link: ''
			},
      contract_id: null,
			form: {
				address: '',
				repr: 'Мальчихина Александра Викторовича',
				full_name: '',
				dog_num: '',
				city: '',
				formatted_date: '',
				org: 'ООО "МПК" — 2',
				pay_made: 0,
				pay_med: 0,
				pay_law: 0,
				pay_spec: 0,
				default_pay_med: 0,
				default_pay_law: 0,
				default_pay_spec: 0,
        default_pay_software_unlimited: 0,
        default_pay_software_urgent: 0,
				cost_med: 0,
				cost_law: 0,
				back: '',
				refund: 0,
				retention_med: 0,
				retention_law: 0,
        retention_spec: 0,
				refund_med: 0,
				refund_law: 0,
				refund_spec: 0,
        pay_software_unlimited: 0,
        pay_software_urgent: 0,
        amount_retention_spec: 0,
        amount_retention_unlimited: 0,
        amount_retention_urgent: 0,
        amount_refund_unlimited: 0,
        amount_refund_urgent: 0,
				nds: 0,
				nds2: 0,
				nds_percent: 20,
				bik: '',
				rs: '',
				bank: '',
				passport_num: '',
				passport_data: '',
				client_name: '',
				client_lastname: '',
				client_middle_name: '',
				bank_contract: '',
				credit_date_create: '',
				termination_date: this.$moment().format('YYYY-MM-DD'),
				conclusion_date: '',
				full_months: 0,
				period_cost_law: 0,
				period_cost_med: 0,
        in_agreement: false,
			},
			backOptions: [],
			orgValues: [
				{text: 'ООО "МПК" — 2', value: 'ООО "МПК" — 2'},
				{text: 'ООО "Легалтек"', value: 'ООО "Легалтек"'},
        {text: 'ЛТ 21', value: 'ЛТ 21'},
			],
			stepIndex: 0,
			totalRefundShow: false,
			retentionMedShow: false,
			retentionLawShow: false,
      retentionSpecShow: false,
			dateTerminationShow: false,
			fullMonthsShow: false,
			paySpecShow: false,
			payMedShow: false,
			payLawShow: false,
			refundSpecShow: false,
			refundMedShow: false,
			refundLawShow: false,
			costPeriodMedShow: false,
			costPeriodLawShow: false,
			ndsRefundShow: false,
			ndsRetentionShow: false,
			bankBikShow: false,
			bankRSShow: false,
			bankNameShow: false,
			creditInfoShow: false,
			costMedShow: false,
			costLawShow: false,
      paySoftwareUnlimitedShow: false,
      paySoftwareUrgentShow: false,
      amountRetentionSpecShow: false,
      amountRetentionUnlimitedShow: false,
      amountRetentionUrgentShow: false,
      amountRefundUnlimitedShow: false,
      amountRefundUrgentShow: false,
			hideBeforeAjax: false,
			hideAfterAjax: false,
			viewOptions: {
				'ООО "МПК" — 2': {
					backOptions: [
						{text: 'Без возврата частичная оплата', value: 'Без возврата частичная оплата', disabled: false},
						{text: 'Без возврата полная оплата', value: 'Без возврата полная оплата', disabled: false},
						{text: 'Фикса', value: 'Фикса', disabled: false}
					],
					backOptionValues: {
						'Без возврата частичная оплата': {
							totalRefundShow: false,
							retentionMedShow: true,
							retentionLawShow: true,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: false,
							paySpecShow: false,
							payMedShow: false,
							payLawShow: false,
							refundSpecShow: false,
							refundMedShow: false,
							refundLawShow: false,
							costPeriodMedShow: false,
							costPeriodLawShow: false,
							ndsRefundShow: false,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Без возврата полная оплата': {
							totalRefundShow: false,
							retentionMedShow: true,
							retentionLawShow: true,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: false,
							paySpecShow: false,
							payMedShow: false,
							payLawShow: false,
							refundSpecShow: false,
							refundMedShow: false,
							refundLawShow: false,
							costPeriodMedShow: false,
							costPeriodLawShow: false,
							ndsRefundShow: false,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Фикса': {
							totalRefundShow: true,
							retentionMedShow: true,
							retentionLawShow: true,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: false,
							paySpecShow: false,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: false,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: false,
							costPeriodLawShow: false,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						}
					}
				},
				'ООО "Легалтек"': {
					backOptions: [
						{text: 'Без возврата при полной оплате', value: 'Без возврата при полной оплате', disabled: false},
						{text: 'Без возврата при частичной оплате', value: 'Без возврата при частичной оплате', disabled: false},
						{text: 'Возврат частичный оплата полная', value: 'Возврат частичный оплата полная', disabled: false},
						{text: 'Возврат по договору полная оплата', value: 'Возврат по договору полная оплата', disabled: false},
						{text: 'Возврат по договору частиная оплата', value: 'Возврат по договору частиная оплата', disabled: false},
						{text: 'Возврат полная оплата', value: 'Возврат полная оплата', disabled: false},
						{text: 'Без спецификации', value: 'Без спецификации', disabled: false}
					],
					backOptionValues: {
						'Без возврата при полной оплате': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: false,
							payMedShow: false,
							payLawShow: false,
							refundSpecShow: false,
							refundMedShow: false,
							refundLawShow: false,
							costPeriodMedShow: true,
							costPeriodLawShow: true,
							ndsRefundShow: false,
							ndsRetentionShow: true,
							bankBikShow: false,
							bankRSShow: false,
							bankNameShow: false,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Без возврата при частичной оплате': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: true,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: false,
							refundMedShow: false,
							refundLawShow: false,
							costPeriodMedShow: true,
							costPeriodLawShow: true,
							ndsRefundShow: false,
							ndsRetentionShow: true,
							bankBikShow: false,
							bankRSShow: false,
							bankNameShow: false,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Возврат частичный оплата полная': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: true,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: true,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: true,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: true,
							costPeriodLawShow: true,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: true,
							costLawShow: true,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Возврат по договору полная оплата': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: true,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: true,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: true,
							costPeriodLawShow: true,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: true,
							costLawShow: true,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Возврат по договору частиная оплата': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: true,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: true,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: true,
							costPeriodLawShow: true,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: true,
							costLawShow: true,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Возврат полная оплата': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: true,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: true,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: false,
							costPeriodLawShow: false,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						},
						'Без спецификации': {
							totalRefundShow: false,
							retentionMedShow: false,
							retentionLawShow: false,
              retentionSpecShow: false,
							dateTerminationShow: true,
							fullMonthsShow: true,
							paySpecShow: false,
							payMedShow: true,
							payLawShow: true,
							refundSpecShow: false,
							refundMedShow: true,
							refundLawShow: true,
							costPeriodMedShow: false,
							costPeriodLawShow: false,
							ndsRefundShow: true,
							ndsRetentionShow: true,
							bankBikShow: true,
							bankRSShow: true,
							bankNameShow: true,
							creditInfoShow: false,
							costMedShow: false,
							costLawShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
						}
					},
				},
        'ЛТ 21': {
          backOptions: [
            {text: 'Без возврата - полная оплата', value: 'Без возврата - полная оплата', disabled: false},
            {text: 'Без возврата - частичная оплата/переплата', value: 'Без возврата - частичная оплата/переплата', disabled: false},
            {text: 'Полный возврат', value: 'Полный возврат', disabled: false},
            {text: 'Возврат по договору без ПО срочное', value: 'Возврат по договору без ПО срочное', disabled: false},
            {text: 'Возврат по договору с ПО срочное - полная оплата', value: 'Возврат по договору с ПО срочное - полная оплата', disabled: false},
            {text: 'Возврат по договору с ПО срочное - частичная оплата/переплата', value: 'Возврат по договору с ПО срочное - частичная оплата/переплата', disabled: false},
            {text: 'Возврат по АД фикс - полная оплата', value: 'Возврат по АД фикс - полная оплата', disabled: false},
            {text: 'Возврат по АД фикс - частичная оплата/переплата', value: 'Возврат по АД фикс - частичная оплата/переплата', disabled: false},
            {text: 'Возврат по АД фикс', value: 'Возврат по АД фикс', disabled: false},
          ],
          backOptionValues: {
            'Без возврата - полная оплата': {
              totalRefundShow: false,
              costMedShow: false,
              costLawShow: false,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: false,
              payMedShow: false,
              payLawShow: false,
              refundSpecShow: false,
              refundMedShow: false,
              refundLawShow: false,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: false,
              ndsRetentionShow: true,
              bankBikShow: false,
              bankRSShow: false,
              bankNameShow: false,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Без возврата - частичная оплата/переплата': {
              totalRefundShow: false,
              costMedShow: false,
              costLawShow: false,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: false,
              refundMedShow: false,
              refundLawShow: false,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: false,
              ndsRetentionShow: true,
              bankBikShow: false,
              bankRSShow: false,
              bankNameShow: false,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: true,
              paySoftwareUrgentShow: true,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Полный возврат': {
              totalRefundShow: false,
              costMedShow: false,
              costLawShow: false,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: true,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: false,
              costPeriodLawShow: false,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Возврат по договору без ПО срочное': {
              totalRefundShow: false,
              costMedShow: false,
              costLawShow: false,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: false,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: false,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: false,
              costPeriodLawShow: false,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: true,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: true,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: true,
              amountRefundUrgentShow: true,
            },
            'Возврат по договору с ПО срочное - полная оплата': {
              totalRefundShow: false,
              costMedShow: true,
              costLawShow: true,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: false,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: false,
              costPeriodLawShow: false,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Возврат по договору с ПО срочное - частичная оплата/переплата': {
              totalRefundShow: false,
              costMedShow: true,
              costLawShow: true,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: true,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: true,
              paySoftwareUrgentShow: true,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: true,
              amountRefundUrgentShow: true,
            },
            'Возврат по АД фикс - полная оплата': {
              totalRefundShow: false,
              costMedShow: true,
              costLawShow: true,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: true,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: false,
              paySoftwareUrgentShow: false,
              amountRetentionSpecShow: false,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Возврат по АД фикс - частичная оплата/переплата': {
              totalRefundShow: false,
              costMedShow: true,
              costLawShow: true,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: true,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: true,
              paySoftwareUrgentShow: true,
              amountRetentionSpecShow: true,
              amountRetentionUnlimitedShow: false,
              amountRetentionUrgentShow: false,
              amountRefundUnlimitedShow: false,
              amountRefundUrgentShow: false,
            },
            'Возврат по АД фикс': {
              totalRefundShow: false,
              costMedShow: false,
              costLawShow: false,
              retentionMedShow: false,
              retentionLawShow: false,
              retentionSpecShow: false,
              dateTerminationShow: true,
              fullMonthsShow: true,
              paySpecShow: true,
              payMedShow: true,
              payLawShow: true,
              refundSpecShow: true,
              refundMedShow: true,
              refundLawShow: true,
              costPeriodMedShow: true,
              costPeriodLawShow: true,
              ndsRefundShow: true,
              ndsRetentionShow: false,
              bankBikShow: true,
              bankRSShow: true,
              bankNameShow: true,
              creditInfoShow: false,
              paySoftwareUnlimitedShow: true,
              paySoftwareUrgentShow: true,
              amountRetentionSpecShow: true,
              amountRetentionUnlimitedShow: true,
              amountRetentionUrgentShow: true,
              amountRefundUnlimitedShow: true,
              amountRefundUrgentShow: true,
            }
          }
        }
			}
		};
	},
	methods: {
		...mapActions(['TERM_DOCGEN_KAZFINDER', 'TERM_DOCGEN_GENERATE', 'ADD_NOTIFICATION_TO_QUERY', 'TERM_DOCGEN_GET_CONTRACT', 'TERM_DOCGEN_SAVE_CONTRACT']),

		async init() {
			this.stepIndex = 0;
			this.form.org = 'ООО "МПК" — 2';
			this.orgAction();
		},

		orgAction() {
			let viewOptions = this.viewOptions[this.form.org];
			if (viewOptions) {
				this.backOptions = viewOptions.backOptions;
				this.defaultBackSelect();
        this.defaultValues();
			}
		},

		defaultBackSelect() {
			this.totalRefundShow = false;
			this.retentionMedShow = false;
			this.retentionLawShow = false;
			this.dateTerminationShow = false;
			this.fullMonthsShow = false;
			this.paySpecShow = false;
			this.payMedShow = false;
			this.payLawShow = false;
			this.refundSpecShow = false;
			this.refundMedShow = false;
			this.refundLawShow = false;
			this.costPeriodMedShow = false;
			this.costPeriodLawShow = false;
			this.ndsRefundShow = false;
			this.ndsRetentionShow = false;
			this.bankBikShow = false;
			this.bankRSShow = false;
			this.bankNameShow = false;
			this.creditInfoShow = false;
      this.paySoftwareUnlimitedShow = false;
      this.paySoftwareUrgentShow = false;
      this.amountRetentionSpecShow = false;
      this.amountRetentionUnlimitedShow = false;
      this.amountRetentionUrgentShow = false;
      this.amountRefundUnlimitedShow = false;
      this.amountRefundUrgentShow = false;
		},

		defaultValues() {
			this.form.pay_law = 0;
			this.form.pay_med = 0;
			this.form.pay_spec = 0;
			this.form.period_cost_med = 0;
			this.form.period_cost_law = 0;
			this.form.refund_med = 0;
			this.form.refund_law = 0;
			this.form.refund_spec = 0;
			this.form.retention_med = 0;
			this.form.retention_law = 0;
			this.form.nds = 0;
			this.form.nds2 = 0;
      this.form.pay_software_unlimited = 0;
      this.form.pay_software_urgent = 0;
      this.form.amount_retention_spec = 0;
      this.form.amount_retention_unlimited = 0;
      this.form.amount_retention_urgent = 0;
      this.form.amount_refund_unlimited = 0;
      this.form.amount_refund_urgent = 0;
		},

		backSelect() {
			let viewOptions = this.viewOptions[this.form.org];
			let viewOptionValues = viewOptions.backOptionValues[this.form.back];
			if (viewOptionValues) {
				this.totalRefundShow = viewOptionValues.totalRefundShow;
				this.retentionMedShow = viewOptionValues.retentionMedShow;
				this.retentionLawShow = viewOptionValues.retentionLawShow;
        this.retentionSpecShow = viewOptionValues.retentionSpecShow;
				this.dateTerminationShow = viewOptionValues.dateTerminationShow;
				this.fullMonthsShow = viewOptionValues.fullMonthsShow;
				this.paySpecShow = viewOptionValues.paySpecShow;
				this.payMedShow = viewOptionValues.payMedShow;
				this.payLawShow = viewOptionValues.payLawShow;
				this.refundSpecShow = viewOptionValues.refundSpecShow;
				this.refundMedShow = viewOptionValues.refundMedShow;
				this.refundLawShow = viewOptionValues.refundLawShow;
				this.costPeriodMedShow = viewOptionValues.costPeriodMedShow;
				this.costPeriodLawShow = viewOptionValues.costPeriodLawShow;
				this.ndsRefundShow = viewOptionValues.ndsRefundShow;
				this.ndsRetentionShow = viewOptionValues.ndsRetentionShow;
				this.bankBikShow = viewOptionValues.bankBikShow;
				this.bankRSShow = viewOptionValues.bankRSShow;
				this.bankNameShow = viewOptionValues.bankNameShow;
				this.creditInfoShow = viewOptionValues.creditInfoShow;
				this.costLawShow = viewOptionValues.costLawShow;
				this.costMedShow = viewOptionValues.costMedShow;
        this.paySoftwareUnlimitedShow = viewOptionValues.paySoftwareUnlimitedShow;
        this.paySoftwareUrgentShow = viewOptionValues.paySoftwareUrgentShow;
        this.amountRetentionSpecShow = viewOptionValues.amountRetentionSpecShow;
        this.amountRetentionUnlimitedShow = viewOptionValues.amountRetentionUnlimitedShow;
        this.amountRetentionUrgentShow = viewOptionValues.amountRetentionUrgentShow;
        this.amountRefundUnlimitedShow = viewOptionValues.amountRefundUnlimitedShow;
        this.amountRefundUrgentShow = viewOptionValues.amountRefundUrgentShow;
        this.defaultValues();
				this.calculateFields();
			}
		},

		calculateFields() {
			switch (this.form.org) {
			case 'ООО "МПК" — 2':
				this.calculateFieldsMPK();
				break;
			case 'ООО "Легалтек"':
				this.calculateFieldsLT();
				break;
        case 'ЛТ 21':
          this.calculateFieldsLT21();
          break;
			}
		},

		actionChangeTerminationDate() {
			this.calculateFullMonths();
			this.calculateFields();
		},

    actionChangeFullMonth() {
      this.calculateFields();
    },

		actionChangeRefund() {
			if (this.form.refund > this.form.pay_made) {
				this.form.refund = this.form.pay_made;
			}
			this.calculateFields();
		},

		calculateFullMonths() {
      let dateStart = new Date(this.form.conclusion_date);
      let dateFinish = new Date(this.form.termination_date);

      if (dateFinish < dateStart) {
        this.form.full_months = 0;
        return;
      }

      let monthsPassed = (dateFinish.getFullYear() - dateStart.getFullYear()) * 12 + (dateFinish.getMonth() - dateStart.getMonth());

      if (monthsPassed > 24) {
        monthsPassed = 24;
      }

      this.form.full_months = Number(monthsPassed);
		},

    calculateFieldsLT21() {
      this.form.pay_law = this.form.default_pay_law;
      this.form.pay_med = this.form.default_pay_med;
      this.form.pay_spec = this.form.default_pay_spec;
      this.form.pay_software_unlimited = this.form.default_pay_software_unlimited;
      this.form.pay_software_urgent = this.form.default_pay_software_urgent;

      switch (this.form.back) {
        case 'Без возврата - полная оплата':
        case 'Без возврата - частичная оплата/переплата':
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.period_cost_med = Number(this.form.pay_med / this.form.full_months).toFixed(2);
          this.form.period_cost_law = Number(this.form.pay_law / this.form.full_months).toFixed(2);
          break;
        case 'Полный возврат':
          this.form.refund_spec = this.form.pay_spec;
          this.form.refund_med = this.form.pay_med;
          this.form.refund_law = this.form.pay_law;
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          break;
        case 'Возврат по договору без ПО срочное':
          this.form.refund_med = this.form.pay_med;
          this.form.refund_law = this.form.pay_law;
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.amount_refund_unlimited = this.form.pay_software_unlimited;
          this.form.amount_refund_urgent = this.form.pay_software_urgent;
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          this.form.amount_retention_spec = Number(
              Number(this.form.pay_software_unlimited) +
              Number(this.form.pay_software_urgent) -
              Number(this.form.amount_refund_urgent)
          ).toFixed(2);
          break;
        case 'Возврат по договору с ПО срочное - полная оплата':
        case 'Возврат по договору с ПО срочное - частичная оплата/переплата':
          this.form.refund_spec = this.form.pay_spec;
          this.form.period_cost_med = Number(this.form.pay_med / 24).toFixed(2);
          this.form.period_cost_law = Number(this.form.pay_law / 24).toFixed(2);
          this.form.refund_med = Number(this.form.pay_med - (this.form.period_cost_med * this.form.full_months)).toFixed(2);
          this.form.refund_law = Number(this.form.pay_law - (this.form.period_cost_law * this.form.full_months)).toFixed(2);
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          break;
        case 'Возврат по АД фикс - полная оплата':
          this.form.period_cost_med = Number(this.form.pay_med / 24).toFixed(2);
          this.form.period_cost_law = Number(this.form.pay_law / 24).toFixed(2);
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.amount_retention_urgent = Number(
              Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_urgent)
          ).toFixed(2);
          this.form.amount_refund_unlimited = Number(
              Number(this.form.pay_software_unlimited) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          this.form.amount_retention_spec = Number(this.form.amount_retention_urgent + this.form.amount_refund_unlimited).toFixed(2);
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          break;
        case 'Возврат по АД фикс - частичная оплата/переплата':
          this.form.period_cost_med = Number(this.form.pay_med / 24).toFixed(2);
          this.form.period_cost_law = Number(this.form.pay_law / 24).toFixed(2);
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.amount_refund_unlimited = this.form.pay_software_unlimited;
          this.form.amount_refund_urgent = this.form.pay_software_urgent;
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          break;
        case 'Возврат по АД фикс':
          this.form.period_cost_med = Number(this.form.pay_med / 24).toFixed(2);
          this.form.period_cost_law = Number(this.form.pay_law / 24).toFixed(2);
          this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
          this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
          this.form.amount_retention_unlimited = Number(
              Number(this.form.pay_software_unlimited) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          this.form.amount_retention_urgent = Number(
              Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_urgent)
          ).toFixed(2);
          this.form.amount_retention_spec = Number(
              Number(this.form.amount_refund_unlimited) + Number(this.form.amount_refund_urgent)
          ).toFixed(2);
          this.form.refund_spec = Number(
              Number(this.form.pay_software_unlimited) + Number(this.form.pay_software_urgent) - Number(this.form.amount_refund_unlimited)
          ).toFixed(2);
          break;
      }
    },

		calculateFieldsLT() {
			this.form.pay_law = this.form.default_pay_law;
			this.form.pay_med = this.form.default_pay_med;
			this.form.pay_spec = this.form.default_pay_spec;
      this.form.pay_software_unlimited = this.form.default_pay_software_unlimited;
      this.form.pay_software_urgent = this.form.default_pay_software_urgent;

			switch (this.form.back) {
			case 'Без возврата при полной оплате':
			case 'Без возврата при частичной оплате':
				this.form.nds2 = Number(this.form.pay_law / 6).toFixed(2);
        this.form.period_cost_med = Number(this.form.pay_med / this.form.full_months).toFixed(2);
        this.form.period_cost_law = Number(this.form.pay_law / this.form.full_months).toFixed(2);
				break;
			case 'Возврат частичный оплата полная':
				if (this.form.refund_med) {
					this.form.period_cost_med = Number((this.form.pay_med - this.form.refund_med) / this.form.full_months).toFixed(2);
				} else {
					this.form.period_cost_med = Number(this.form.pay_med / this.form.full_months).toFixed(2);
				}
				if (this.form.refund_law) {
					this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
					this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
					this.form.period_cost_law = Number((this.form.pay_law - this.form.refund_law) / this.form.full_months).toFixed(2);
				} else {
					this.form.period_cost_law = Number(this.form.pay_law / this.form.full_months).toFixed(2);
					this.form.nds2 = Number(this.form.pay_law / 6).toFixed(2);
				}
        this.form.retention_spec = Number(this.form.pay_spec - this.form.refund_spec).toFixed(2);
				break;
			case 'Возврат по договору полная оплата':
			case 'Возврат по договору частиная оплата':
				this.form.refund_spec = this.form.pay_spec;
				this.form.period_cost_med = Number(this.form.cost_med / 24).toFixed(2);
				this.form.period_cost_law = Number(this.form.cost_law / 24).toFixed(2);
				this.form.refund_med = Number(this.form.pay_med - this.form.period_cost_med * this.form.full_months).toFixed(2);
				this.form.refund_law = Number(this.form.pay_law - this.form.period_cost_law * this.form.full_months).toFixed(2);
				this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
				this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
				break;
			case 'Возврат полная оплата':
				this.form.refund_spec = this.form.pay_spec;
				this.form.refund_med = this.form.pay_med;
				this.form.refund_law = this.form.pay_law;
				this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
				this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
				break;
			case 'Без спецификации':
				this.form.refund_med = this.form.pay_med;
				this.form.refund_law = this.form.pay_law;
				this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
				this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
				break;
			}
		},

		calculateFieldsMPK() {
			this.form.pay_law = Number(this.form.pay_made * 30 / 100).toFixed(2);
			this.form.pay_med = Number(this.form.pay_made * 70 / 100).toFixed(2);
			if (this.form.refund > 0) {
				this.form.refund_law = Number(this.form.refund / 100 * 30).toFixed(2);
				this.form.refund_med = Number(this.form.refund / 100 * 70).toFixed(2);
				this.form.retention_law = Number((this.form.pay_made - this.form.refund) / 100 * 30).toFixed(2);
				this.form.retention_med = Number((this.form.pay_made - this.form.refund) / 100 * 70).toFixed(2);
				this.form.nds = Number(this.form.refund_law / 6).toFixed(2);
				this.form.nds2 = Number((this.form.pay_law - this.form.refund_law) / 6).toFixed(2);
				this.form.period_cost_law = Number((this.form.pay_law - this.form.refund_law) / this.form.full_months).toFixed(2);
				this.form.period_cost_med = Number((this.form.pay_med - this.form.refund_med) / this.form.full_months).toFixed(2);
			} else {
				this.form.retention_law = Number(this.form.pay_made / 100 * 30).toFixed(2);
				this.form.retention_med = Number(this.form.pay_made / 100 * 70).toFixed(2);
				this.form.nds2 = Number(this.form.pay_law / 6).toFixed(2);
				this.form.period_cost_law = Number(this.form.pay_law / this.form.full_months).toFixed(2);
				this.form.period_cost_med = Number(this.form.pay_med / this.form.full_months).toFixed(2);
			}
		},

		async next() {
			if (this.stepIndex === 0) {
				this.hideBeforeAjax = false;

        await this.TERM_DOCGEN_GET_CONTRACT(this.finder).then(() => {
          if (this.contract) {
            console.log(this.contract);

            this.hideBeforeAjax = true;
            this.hideAfterAjax = false;
            this.stepIndex++;
            return;
          }
        }).catch((e) => {
          console.log(e);
          this.ADD_NOTIFICATION_TO_QUERY({
            type: 'error', title: 'Ошибка',
            message: 'По какой-то причине не удалось получить информацию о договоре.'
          });
          this.back();
        });

				await this.TERM_DOCGEN_KAZFINDER(this.finder).then(() => {
					this.form.city = this.term_docgen.city;
					this.form.full_name = this.term_docgen.lastname + ' ' + this.term_docgen.firstname + ' ' + this.term_docgen.middlename;
					this.form.dog_num = this.term_docgen.contract_id;
					this.form.pay_made = this.term_docgen.paid;
					this.form.default_pay_law = Number(this.term_docgen.law_paid / 100).toFixed(2);
					this.form.default_pay_med = Number(this.term_docgen.med_paid / 100).toFixed(2);
					this.form.default_pay_spec = Number(this.term_docgen.spec_paid / 100).toFixed(2);
          this.form.default_pay_software_unlimited = Number(this.term_docgen.software_paid / 100).toFixed(2);
          this.form.default_pay_software_urgent = Number(this.term_docgen.software_chat_module_paid / 100).toFixed(2);
					this.form.cost_med = Number(this.term_docgen.cost_med / 100).toFixed(2);
					this.form.cost_law = Number(this.term_docgen.cost_law / 100).toFixed(2);
					this.form.passport_num = this.term_docgen.passport_num;
					this.form.passport_data = this.term_docgen.passport_data;
					this.form.client_name = this.term_docgen.firstname;
					this.form.client_lastname = this.term_docgen.lastname;
					this.form.client_middle_name = this.term_docgen.middlename;
					this .form.bank_contract = this.term_docgen.bank_contract;
					this.form.credit_date_create = this.term_docgen.credit_date_create;
					this.form.address = this.term_docgen.address;

					this.form.conclusion_date = this.$moment(this.term_docgen.start_date * 1000).format('YYYY-MM-DD');
					this.form.formatted_date = this.$moment(this.term_docgen.start_date * 1000).format('D MMMM YYYY г.');
					this.calculateFullMonths();

					this.hideBeforeAjax = true;
					this.hideAfterAjax = false;
          this.stepIndex++;
          return;
				}).catch((e) => {
					console.log(e);
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error', title: 'Ошибка',
						message: 'По какой-то причине не удалось получить информацию о договоре.'
					});
					this.back();
				});
			} else if (this.stepIndex === 1) {

        console.log(this.finder.link)

        await this.TERM_DOCGEN_SAVE_CONTRACT(
            { link: this.finder.link, contractData: this.form }
        )
            .then(() => {
              this.stepIndex++;
            })
            .catch((e) => {
              console.log(e);
              this.ADD_NOTIFICATION_TO_QUERY({
                type: 'error',
                title: 'Ошибка',
                message: 'По какой-то причине не удалось сохранить информацию о договоре.'
              });
            });
        this.stepIndex++;
      } else if (this.stepIndex === 2) {
        return;
      } else {
        this.stepIndex++;
      }
		},

		async generate() {
			await this.TERM_DOCGEN_GENERATE(this.form).then(() => {
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error', title: 'Ошибка',
					message: 'Ошибка генерации договора.'
				});
				this.back();
			});
		},

		back() {
			this.stepIndex--;
		}
	},
	async mounted() {
		await this.init();
	}
};
</script>

<style scoped>
input, p {
	font-size: 16px;
}

p {
	margin: 0;
}

span {
	font-size: 14px;
}

label {
	font-weight: bold;
}

.checkbox_list {
	display: inline-block;
}

.checkbox_list > label {
	margin: 0;
}

.checkbox_container {
	display: inline-block;
	margin: 0 2.5rem;
}

.sum-container {
	display: block;
}

input[type="text"], select {
	color: #000 !important;
	font-size: 16px !important;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.checkbox-container input {
  margin-right: 10px;
}
.checkbox-container label{
  margin: 0;
}
.button-container {
  margin-bottom: 10px;
}
</style>
